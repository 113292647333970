import _isAlphabetical from "is-alphabetical";
import _isDecimal from "is-decimal";
var exports = {};
var alphabetical = _isAlphabetical;
var decimal = _isDecimal;
exports = alphanumerical; // Check if the given character code, or the character code at the first
// character, is alphanumerical.

function alphanumerical(character) {
  return alphabetical(character) || decimal(character);
}

export default exports;